import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentSpinner.figmaUrl.android} codeUrl={checklists.componentSpinner.codeUrl.android} checklists={checklists.componentSpinner.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <p>{`Spinner is a component to indicate a loading state or progress state of a page or section.`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/spinner/android-2.png",
            "alt": "spinner banner"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <h2>{`Usage`}</h2>
    <p>{`The following is code to implement spinner component.`}</p>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.spinner.LgnSpinner
    android:layout_width="@dimen/dimen_32dp"
    android:layout_height="@dimen/dimen_32dp"
    android:layout_marginStart="@dimen/dimen_16dp"
    android:layout_marginTop="@dimen/dimen_16dp"
    app:variant="primary" />
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.spinner.LgnSpinner
    android:layout_width="@dimen/dimen_32dp"
    android:layout_height="@dimen/dimen_32dp"
    android:layout_marginStart="@dimen/dimen_16dp"
    android:layout_marginTop="@dimen/dimen_16dp"
    app:variant="secondary" />
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.spinner.LgnSpinner
    android:layout_width="@dimen/dimen_32dp"
    android:layout_height="@dimen/dimen_32dp"
    android:layout_marginStart="@dimen/dimen_16dp"
    android:layout_marginTop="@dimen/dimen_16dp"
    app:variant="error" />
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.spinner.LgnSpinner
    android:layout_width="@dimen/dimen_32dp"
    android:layout_height="@dimen/dimen_32dp"
    android:layout_marginStart="@dimen/dimen_16dp"
    android:layout_marginTop="@dimen/dimen_16dp"
    app:variant="white" />
`}</code></pre>
    <h4>{`Programatically`}{`*`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
binding.loading.variant = LgnSpinner.Variant.PRIMARY
...
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
binding.loading.variant = LgnSpinner.Variant.SECONDARY
...
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
binding.loading.variant = LgnSpinner.Variant.ERROR
...
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
binding.loading.variant = LgnSpinner.Variant.WHITE
...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <div className="divi" />
    <h3><strong parentName="h3">{`Variants`}</strong></h3>
    <p>{`Default usage of Spinner and its behavior being `}<inlineCode parentName="p">{`primary`}</inlineCode>{` in variants.`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/spinner/android-1.png",
            "alt": "spinner android"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Xml Attrs`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Related method(s)`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:height`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`variant`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:variant`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`variant`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:width`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      